import Vue from 'vue'
import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchClients(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/invoice/invoices', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchInvoice(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/invoice/invoices/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getClient(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'clients/getClient', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    savePreferences(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'clients/savePreferences', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    saveTasks(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'clients/saveTasks', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateOtherExpenses(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'clients/updateOtherExpenses', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateTasks(ctx, form) {
      const accessToken = localStorage.getItem('accessToken')

      const formData = new FormData()
      Object.entries(form).forEach(([key, val]) => {
        if (Array.isArray(val)) {
          if (val[0] === Object(val[0]) && (val[0] instanceof File) === false) {
            formData.append(key, JSON.stringify(val))
          } else {
            val.forEach(value => formData.append(`${key}[]`, value))
          }
        } else {
          formData.append(key, val)
        }
      })

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'clients/updateTasks', formData, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    signClientCarelogs(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'clients/signClientCarelogs', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getPreferences(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'clients/getPreferences', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getTasks(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'clients/getTasks2', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTask(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'clients/fetchTask', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getCaregiverTasks(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'caregiverTask/fetchShifts', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getShiftCalendarByYear(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'clients/getShiftCalendarByYear', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getFamily(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'clients/getFamily', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getClientDetails(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'clients/getClientDetails', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getTaskDetails(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'clients/getTaskDetails', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    activateClient(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'clients/activateClient', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deactivateClient(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'clients/deactivateClient', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteClientSignature(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'clients/deleteClientSignature', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCaregiverSignature(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'clients/deleteCaregiverSignature', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
