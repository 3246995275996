<!-- eslint-disable -->
<template>
  <div>
    <b-row>
      <b-col lg="12" cols="12">
        <!-- <div class="pageTitle">
           <h1> {{ pageTitle }} <span class="text-black-50"> |</span></h1>
        </div> -->
        <div class="row breadcrumbs-top">
          <div class="col-12">
            <h2 class="content-header-title float-left pr-1 mb-0"> {{ pageTitle }} </h2>
            <div class="breadcrumb-wrapper">
              <ol class="breadcrumb">
                <!-- <li class="breadcrumb-item">
                  <feather-icon
                    icon="HomeIcon"
                    class="text-primary"
                    size="16"
                  />
                </li> -->
                <li class="breadcrumb-item">
                  <a
                    href="#"
                    class="router-link-active"
                    target="_self"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16px"
                      height="16px"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="align-text-top feather feather-home"
                    >
                      <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
                      <polyline points="9 22 9 12 15 12 15 22" />
                    </svg>
                  </a>
                </li>
                <li class="breadcrumb-item">
                  <a
                    @click="pageTitle === 'Clients' ? $router.push({ name: 'clients'  }) : $router.push({ name: 'my-clients'  })"
                    target="_self"
                    class="text-primary"
                  > {{ pageTitle }} </a>
                </li>
                <li class="breadcrumb-item">
                  <a
                    @click="pageTitle === 'Clients' ? $router.push({ name: 'client-profile', params: { action: 'edit' }, query: { id: clientId }  }) : $router.push({ name: 'caregiver-client-profile', params: {},query: { id: data.item.id } })"
                    target="_self"
                    class="text-primary-dark"
                  > {{ clientInfo }} </a>
                </li>
                <li class="breadcrumb-item">
                  <a
                    href="#"
                    target="_self"
                    class="text-primary-dark"
                  > {{ pagelabel }} </a>
                </li>
                <li class="breadcrumb-item"
                  v-if="this.$route.name === 'client-profile-edit'"
                >
                  <a
                    href="#"
                    target="_self"
                    class="text-primary-dark"
                  > Edit </a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </b-col>
      <b-col
        v-if="this.$route.name != 'client-profile-edit'"
      >
        <b-tabs content-class="pt-1" align="left">
          <b-tab
            :active = this.profile
            title="Profile"
            class="text-left"
            @click="$router.push({ name: (userRole === 'admin' ? 'client-profile' : 'caregiver-client-profile'), params: { action: 'edit' }, query: { id: clientId }  })"
          >
          </b-tab>
          <b-tab
            v-if="userRole === 'admin'"
            :active = this.family
            title="Family"
            class="text-left"
            @click="$router.push({ name: 'client-family', params: { action: 'edit' }, query: { id: clientId }  })"
          >
          </b-tab>
          <b-tab
            :active = this.care
            title="Care Assessment"
            class="text-left"
            @click="$router.push({ name: (userRole === 'admin' ? 'admin-client-assessmentcare' : 'caregiver-client-assessmentcare'), params: { tabIndex: `tab-form-${0}`, action: 'edit' }, query: { id: clientId }  })"
          >
          </b-tab>
          <b-tab
            v-if="userRole === 'admin'"
            :active = this.checklist
            title="Home Safety Checklist"
            class="text-left"
            @click="$router.push({ name: 'client-checklist', params: { action: 'edit' }, query: { id: clientId }  })"
          >
          </b-tab>
          <b-tab
            v-if="userRole === 'admin'"
            title="Caregiver Preferences"
            :active = this.preferences
            class="text-left"
            @click="$router.push({ name: 'client-preferences', params: { action: 'edit' }, query: { id: clientId }  })"
          >
          </b-tab>
          <b-tab
            v-if="userRole === 'admin'"
            title="Care Plan"
            :active = this.careplan
            class="text-left"
            @click="$router.push({ name: 'client-careplan', params: { action: 'edit' }, query: { id: clientId }  })"
          >
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable */
// import JQuery from 'jquery'

import { BRow, BCol,BTabs, BTab, BCardText } from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import clientStoreModule from './js/clientStoreModule'

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    BCardText,
  },
  data(){
    return{
      clientId: this.$route.query.id,
      profile: false,
      preferences: false,
      family: false,
      care: false,
      checklist: false,
      careplan: false,
      pagelabel: 'Profile',
      clientInfo: '',
    }
  },
  props: {
    userRole: {
      type: String,
      required: true,
    }
  },
  setup() {
    let pageTitle = ''
    const user = JSON.parse(localStorage.getItem('userData'))
    const userRole = user.role
      if(userRole === 'agent')
        pageTitle = 'My Clients'
      else
        pageTitle = 'Clients'

    return {
      pageTitle,
    }
  },
  created() {
      if(this.$route.name === 'client-preferences') {
          this.preferences = true
          this.pagelabel = 'Caregiver Preferences'
      }
      if(this.$route.name === 'client-profile' || this.$route.name === 'caregiver-client-profile') {
          this.profile = true
      }
      if(this.$route.name === 'client-family') {
        this.family = true
        this.pagelabel = 'Family'
      }
      if(this.$route.name === 'admin-client-assessmentcare' || this.$route.name === 'caregiver-client-assessmentcare') {
        this.care = true
        this.pagelabel = 'Care Assessment'
      }
      if(this.$route.name === 'client-checklist') {
        this.checklist = true
        this.pagelabel = 'Home Safety Checklist'
      }
      if(this.$route.name === 'client-careplan') {
        this.careplan = true
        this.pagelabel = 'Care Plan'
      }
      this.getClientInfo()
  },
  methods: {
  //   getClient() {
  //     store
  //       .dispatch('app-client/getClient', { id: this.clientId })
  //       .then(response => {
  //         // eslint-disable-next-line
  //         const obj = response.data.response.data['clientData'][0]
  //         this.firstname = obj.firstname
  //         this.lastname = obj.lastname
  //         console.log(obj)
  //       })
  //       .catch(error => {
  //         console.error(error)
  //       })
  //   },
    getClientInfo() {
      const formdata = {
        clientId: this.$route.query.id,
      }
      // eslint-disable-next-line
      this.$http.post(this.$apiUrl2 + 'client/getClientInfo', {
        param: formdata,
      },
      {
        headers: {
          'Content-type': 'application/json',
        },
      })
      .then(response => {
        this.clientInfo = response.data.clientInfo
      }, response => {
        //Destroy session
        if(response.status !== 200) {
          this.$router.push({ name: 'login' })
        }
      })
      // eslint-disable-next-line
      .catch(error =>  {
          // console.log(error.response.status)
      })
    },
  },
}
</script>
<style>
.nav-tabs .nav-link.active:after{
background: transparent linear-gradient(93deg, #B9D23E 0%, #569099 100%) 0% 0% no-repeat padding-box !important;
}
.pageTitle{
  display: inline-block;
}
/* .breadcrumb-wrapper{
  display: inline-block;
  margin-top: 4px;
} */
</style>
