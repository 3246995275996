<!-- eslint-disable -->
<template>
  <div>
    <div
      v-if="userRole === 'admin'"
    >
      <client-tabs
        :user-role="userRole"
      />
    </div>
    <div
      v-else
    >
      <h1>Care Plan</h1>
      <div class="page-subtitle">The following are our available services for the well-being of the client.</div>
    </div>
    
    <!--
    <h1 class="mb-3">
      {{ pageTitle }}
    </h1>
    <h5 class="mb-2">
      The following are our available services for the well-being of the client. 
    </h5>
    -->
    <b-row>
      <b-card
        :class="userRole === 'admin' ? 'mt-0 ml-1 mr-1' : 'mt-2 ml-1 mr-1'"
      >
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <b-col
                cols="12"
                class="mt-2 mb-1"
              >
                <b-media vertical-align="top">
                  <template #aside>
                    <b-avatar
                      rounded
                      variant="primary"
                      size="25"
                      style="background-color: white;"
                    >
                      <img
                        src="@/assets/images/icons/services_icon.svg"
                        width="25px"
                        height="25px"
                      >
                    </b-avatar>
                  </template>
                  <h4 class="media-heading mb-0">
                    Services to be Performed
                  </h4>
                </b-media>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Services"
                  label-for="services"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="services"
                    rules="required"
                  >
                    <b-form-checkbox-group
                      v-model="care_plan.services"
                      :state="errors.length > 0 ? false:null"
                      name="services-options"
                    >
                      <b-form-checkbox
                        v-for="option in servicesOptions"
                        :key="option.id"
                        :value="option.value"
                        :disabled="userRole !== 'admin' ? true : false"
                        class="m-0 mb-1 col-sm-4"
                      >
                        {{ option.text }}
                      </b-form-checkbox>
                    </b-form-checkbox-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <hr>
            <b-row>
              <b-col
                cols="12"
                class="mt-2 mb-1"
              >
                <b-media vertical-align="top">
                  <template #aside>
                    <b-avatar
                      rounded
                      variant="primary"
                      size="25"
                      style="background-color: white;"
                    >
                      <feather-icon
                        icon="ClockIcon"
                        size="20"
                        class="feather-icon-custom gray-1"
                      />
                    </b-avatar>
                  </template>
                  <h4 class="media-heading mb-0">
                    Daily Care Routine
                  </h4>
                </b-media>
              </b-col>
              <b-col md="12">
                <b-col
                  md="12"
                  class="py-0 px-0 mt-1"
                >
                  <b-row class="m-0 px-0">
                    <!-- Items Section -->
                    <div
                      ref="routineForm"
                      class="repeater-form h-auto w-100"
                    >
                      <b-row
                        v-for="(routine, index) in care_plan.routines"
                        :key="index"
                        ref="routineRow"
                        class="m-0 p-0"
                      >
                        <!-- Item Form -->
                        <b-col
                          cols="12"
                          class="p-0"
                        >
                          <!-- Form Input Fields OR content inside bordered area  -->
                          <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                          <b-row 
                            class="px-0 m-0"
                          >
                            <b-col
                              md="2"
                              sm="3"
                              class="p-0 pr-1"
                            >
                              <b-form-group
                                :label="care_plan.routines.length > 1 && index > 0 ? '' : 'Time'"
                                label-for="routine_time"
                              >
                                <validation-provider
                                  #default="{ errors }"
                                  name="Routine Time"
                                  :rules="care_plan.routines.length === 1 && care_plan.routines[0].time === '' && care_plan.routines[0].description === '' ? '' : 'required'"
                                  fluid="sm"
                                >
                                  <b-form-timepicker
                                    v-model="routine.time"
                                    now-button
                                    reset-button
                                    :id="'routine_time' + index"
                                    locale="en"
                                    placeholder=""
                                    class="h-auto"
                                    :disabled="userRole !== 'admin' ? true : false"
                                    :state="errors.length > 0 ? false : null"
                                  />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col
                              :md="userRole !== 'admin' ? 10 : 9"
                              sm="5"
                              class="p-0 pr-1"
                            >
                              <b-form-group
                                :label="care_plan.routines.length > 1 && index > 0 ? '' : 'Description'"
                                label-for="routine_description"
                              >
                                <validation-provider
                                  #default="{ errors }"
                                  name="Routine Description"
                                  :rules="care_plan.routines.length === 1 && care_plan.routines[0].time === '' && care_plan.routines[0].description === '' ? '' : 'required'"
                                >
                                  <b-form-input
                                    v-model="routine.description"
                                    id="routine_description"
                                    :disabled="userRole !== 'admin' ? true : false"
                                    :state="errors.length > 0 ? false : null"
                                  />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col
                              v-if="userRole === 'admin' ? true : false"
                              md="1"
                              class="p-0 my-auto"
                            >
                              <div
                                style="margin-left: 10px"
                              >
                                <feather-icon
                                  v-if="care_plan.routines.length !== 1 ? true : false"
                                  size="20"
                                  icon="Trash2Icon"
                                  class="cursor-pointer"
                                  @click="removeRoutine(index)"
                                />
                                <!--
                                <feather-icon
                                  size="20"
                                  icon="PlusIcon"
                                  class="cursor-pointer"
                                  @click="addNewRoutine"
                                />
                                -->
                              </div>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-button
                        v-if="userRole === 'admin' ? true : false"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="secondary"
                        @click="addNewRoutine"
                      >
                        <feather-icon
                          icon="PlusIcon"
                          class="mr-25"
                        />
                        <span>Add New</span>
                      </b-button>
                    </div>
                  </b-row>
                </b-col>
              </b-col>
            </b-row>
            <hr>
            <b-row>
              <b-col
                cols="12"
                class="mt-2 mb-1"
              >
                <b-media vertical-align="top">
                  <template #aside>
                    <b-avatar
                      rounded
                      variant="primary"
                      size="25"
                      style="background-color: white;"
                    >
                      <feather-icon
                        icon="SettingsIcon"
                        size="20"
                        class="feather-icon-custom gray-1"
                      />
                    </b-avatar>
                  </template>
                  <h4 class="media-heading mb-0">
                    Other Service Providers
                    <feather-icon
                      v-b-tooltip.hover.top="'If any, please list out your current service provider for the following item'"
                      icon="InfoIcon"
                      class="cursor-pointer"
                      size="14"
                    />
                  </h4>
                </b-media>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Cleaning Service"
                  label-for="cleaning-service"
                >
                  <b-form-input
                    v-model="care_plan.cleaning"
                    :value="care_plan.cleaning"
                    :disabled="userRole !== 'admin' ? true : false"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Lawn Care"
                  label-for="lawn-care"
                >
                  <b-form-input
                    v-model="care_plan.lawn_care"
                    :value="care_plan.lawn_care"
                    :disabled="userRole !== 'admin' ? true : false"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Hairdresser/Barber"
                  label-for="hairdresser-barber"
                >
                  <b-form-input
                    v-model="care_plan.hairdress"
                    :value="care_plan.hairdress"
                    :disabled="userRole !== 'admin' ? true : false"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Other"
                  label-for="other"
                >
                  <b-form-input
                    v-model="care_plan.other"
                    :value="care_plan.other"
                    :disabled="userRole !== 'admin' ? true : false"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row
              v-if="userRole === 'admin' ? true : false"
              class="mt-2"
            >
              <b-col
                md="10"
              >
                <!-- Action Buttons -->
                <b-button
                  variant="secondary"
                  class="mr-1"
                  type="submit"
                  @click.prevent="validationForm"
                >
                  Save
                </b-button>
                <b-button
                  variant="outline-dark"
                  @click="fetchMyData"
                >
                  Reset
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BTab, BTabs,
  BButton,
  BMedia,
  BRow,
  BCol,
  BForm,
  BCard,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormTimepicker,
  BCardText, 
  BAvatar,
  BTooltip,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ref,
  // eslint-disable-next-line no-unused-vars
  watch,
  computed,
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import { required } from '@validations'
import Swal from 'sweetalert2'
import { heightTransition } from '@core/mixins/ui/transition'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import useAssessmentCare from './useAssessmentCare.js'
import carePlanStoreModule from './CarePlanStoreModule.js'
import ClientTabs from '../clients/ClientTabs.vue'


export default {
  components: {
    BTab,
    BTabs,
    BButton,
    BMedia,
    BRow,
    BCol,
    BForm,
    BCard,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormTimepicker,
    BCardText, 
    BAvatar,
    BTooltip,
    ValidationProvider,
    ValidationObserver,
    ClientTabs,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [heightTransition],
  // data: () => ({
  //   tabIndex: 1,
  // }),
  mounted() {
    this.initTrHeight()
  },
  created() {
    const user = JSON.parse(localStorage.getItem('userData'))
    this.userRole = user.role
    // console.log('this.userRole', this.userRole)
    // store.commit('client_assessmentcare/SET_CLIENT_ID', this.clientId)
    // console.log('this.userRole', this.userRole)
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  setup(props, { emit }) {
    const CARE_PLAN_APP_STORE_MODULE_NAME = 'care_plan'

    // Register module
    if (!store.hasModule(CARE_PLAN_APP_STORE_MODULE_NAME)) {
      store.registerModule(CARE_PLAN_APP_STORE_MODULE_NAME, carePlanStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CARE_PLAN_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(CARE_PLAN_APP_STORE_MODULE_NAME)
      }
    })
    // const tabIndex = ref(1)
    /* const tabIndex = ref(store.state.client_assessmentcare.tabIndex)
    const nextTab = () => {
      store.commit('client_assessmentcare/GO_TO_NEXT_TAB', tabIndex.value += 1)
    } */
    const clientId = router.currentRoute.query.id ? router.currentRoute.query.id : ''
    const userRole = ''
    const care_plan = ref(computed(() => store.state.care_plan.care_plan))
    const simpleRules = ref(null)
    const servicesOptions = [
      {id: 1, text: 'Grooming (Hair, Make-up, Shaving)', value: 'grooming'},
      {id: 2, text: 'Light Housekeeping', value: 'light_housekeeping'},
      {id: 3, text: 'Bathing', value: 'bathing'},
      {id: 4, text: 'Continence', value: 'continence'},
      {id: 5, text: 'Dressing', value: 'dressing'},
      {id: 6, text: 'Eating', value: 'eating'},
      {id: 7, text: 'Toileting', value: 'toileting'},
      {id: 8, text: 'Transferring', value: 'transferring'},
      {id: 9, text: 'Lifestyle Support', value: 'lifestyle_support'},
      {id: 10, text: 'Exercise', value: 'exercise'},
      {id: 11, text: 'Meal Preparation', value: 'meal_preparation'},
      {id: 12, text: 'Medication Reminder', value: 'medical_reminder'},
      {id: 13, text: 'BP/Pulse Monitoring', value: 'bp'},
      {id: 14, text: 'Shopping/Errands', value: 'shopping'},
      {id: 15, text: 'Laundry', value: 'laundry'},
      {id: 16, text: 'Pet Care', value: 'pet_care'},
    ]

    const routineForm = ref(null)
    const routineRow = ref(null)

    const blankItem = {
      time: '',
      description: '',
    }

    // *===============================================---*
    // *--------- RETRIEVE DATA ---------------------------*
    // *===============================================---*
    const fetchMyData = () => {
      store.dispatch('care_plan/getData', clientId)
        .then(response => {
          // console.log('some response message', response.data.response.data)
          // const payload = response.data.response.data
          if (response.data.response.data.length !== 0) {
            const tempData = []
            response.data.response.data.forEach(data => {
              const services = data.services
              const other = data.other
              const routines = data.routines
              tempData
                .push({
                  services: services,
                  cleaning: other.cleaning,
                  lawn_care: other.lawn_care,
                  hairdress: other.hairdress,
                  other: other.other,
                  routines: routines,
                })
            })
            store.commit('care_plan/SET_CARE_PLAN', tempData[0])
          }
        })
        .catch(() => {
          // console.log('some error message', er)
        })
    }
    fetchMyData()

    // *===============================================---*
    // *--------- SAVE DATA ------------------------------*
    // *===============================================---*
    const onSaveData = () => {
      const uData = localStorage.getItem('userData')
      const uID = JSON.parse(uData)
      // console.log('user data', )
      const formData = new FormData()
      const data = JSON.stringify({
        client_id: uID.role === 'admin' ? clientId : uID.clientId,
        services: care_plan.value.services,
        cleaning: care_plan.value.cleaning,
        lawn_care: care_plan.value.lawn_care,
        hairdress: care_plan.value.hairdress,
        other: care_plan.value.other,
        routines: care_plan.value.routines,
      })
      formData.append('data', data)
      /* for (var value of formData.values()) {
        console.log(value);
      } */
      store.dispatch('care_plan/saveData', formData)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Updated Succesfully',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          //emit('fetch-my-data')
          fetchMyData()
        })
        .catch(error => {
          console.error(error)
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            showConfirmButton: false,
            timer: 1500,
          })
          // console.log('error', error)
        })
    }
    const validationForm = () => {
      simpleRules.value.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          // alert('form submitted!')
          onSaveData()
          // console.log('ref.simpleRules', simpleRules.value)
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'Make sure to fill up  the required fields',
            showConfirmButton: false,
            timer: 1500,
          })
        }
      })
    }

    return {
      pageTitle: 'Care Plan',
      userRole,
      clientId,
      required,
      servicesOptions,
      care_plan,
      simpleRules,
      validationForm,
      onSaveData,
      fetchMyData,
      routineForm,
      routineRow,
      blankItem,
    }
  },
  methods: {
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.routineForm.scrollHeight)
      })
    },
    addNewRoutine() {
      // this.routineForm.style.overflow = 'hidden'
      this.care_plan.routines.push(JSON.parse(JSON.stringify(this.blankItem)))
      // console.log(this.care_plan.routines)

      this.$nextTick(() => {
        this.trAddHeight(this.routineRow[0].offsetHeight)
        /* setTimeout(() => {
          this.routineForm.style.overflow = null
        }, 350) */
      })
    },
    removeRoutine(index) {
      this.care_plan.routines.splice(index, 1)
      // console.log(this.care_plan.routines)
      this.trTrimHeight(this.routineRow[0].offsetHeight)
    }
  }
}
</script>

<style lang="scss">
/* [dir] .feather-icon-custom{
  color: #33475B !important
} */
</style>