/* eslint-disable */
import axios from '@axios'
import Vue from 'vue'
import { reactive } from '@vue/composition-api'

export default {
  namespaced: true,
  state: reactive({
    care_plan: {
      services: [],
      cleaning: '',
      lawn_care: '',
      hairdress: '',
      other: '',
      routines: [{
        time: '',
        description: '',
      }],
    }
  }),
  mutations: {
    // SET_USER_ROLE: (state, payload) => (state.uRole = payload),
    SET_CARE_PLAN: (state, payload) => (state.care_plan = payload),
  },
  getters: {
    
  },
  actions: {
    getData(ctx, clientId) {
      const uData = JSON.parse(localStorage.getItem('userData'))
      const clientID = { clientId: clientId, role: uData.role }
      const uDATA = uData.role === 'admin' ? clientID : uData
      // console.log('uDATA this ', uDATA.id)
      const accessToken = localStorage.getItem('accessToken')
      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line prefer-template
          .post(Vue.prototype.$apiUrl2 + 'CarePlan/fetchData', {
            param: {
              userData: uDATA,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => { resolve(response) })
          .catch(error => { reject(error) })
      })
    },
    saveData(ctx, formData) {
      /* for (const [key, value] of formData.entries()) {
        console.log(key, value);
      } */

      const accessToken = localStorage.getItem('accessToken')
      return new Promise((resolve, reject) => {
        axios
          .post(`${Vue.prototype.$apiUrl2}CarePlan/action`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-type': 'multipart/form-data',
              },
            })
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
  }
}